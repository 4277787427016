<template>
    <el-dialog
        :visible.sync="show"
        width="40%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        @close="close"
        :title="info.title"
        center
    >
        <div>
            <!--			<div slot="title" class="header-title">-->
            <!--				<el-row :gutter="5">-->
            <!--					<el-col :span="24">-->
            <!--						<span class="title-name" style="font-size: 20px;">{{ info.title }}</span>-->
            <!--					</el-col>-->
            <!--				</el-row>-->
            <!--			</div>-->
            <!--			<div style="width: 100%;height: 20px;"></div>-->
            <el-row :gutter="0">
                <el-col :span="24">
                    <el-form
                        ref="ruleForm"
                        :model="form"
                        label-width="110px"
                        class="allForm"
                    >
                        <el-form-item label="标题" :required="true">
                            <el-input
                                size="mini"
                                v-model="form.bannerTittle"
                                class="formInput"
                                maxlength="25"
                            />
                        </el-form-item>
                        <el-form-item label="封面图" :required="true">
                            <el-upload
                                ref="upload"
                                class="upload-demo w-100"
                                drag
                                :headers="{
                                    Authorization: $token,
                                }"
                                :action="this.$srcUrl + upFile"
                                list-type="picture"
                                :file-list="fileList"
                                :auto-upload="true"
                                :limit="1"
                                :on-success="success"
                                :on-change="change"
                                :on-exceed="overFile"
                                :on-remove="onRemove"
                            >
                                <div style="height: 25px"></div>
                                <i
                                    class="el-icon-receiving"
                                    style="font-size: 80px"
                                />
                                <div class="el-upload__text">
                                    将文件拖到此处，或<em>点击上传</em>，只支持传一张图片
                                </div>
                            </el-upload>
                        </el-form-item>
                        <!-- <el-form-item label="排序号" :required="true">
                            <el-input
                                size="mini"
                                v-model="form.sort"
                                type="number"
                                class="formInput"
                                maxlength="25"
                            />
                        </el-form-item> -->
                        <el-form-item label="类型" :required="true">
                            <el-radio-group
                                size="mini"
                                v-model="form.bannerType"
                            >
                                <!-- <el-radio-button label="0">内部链接</el-radio-button> -->
                                <el-radio-button label="1"
                                    >外部链接</el-radio-button
                                >
                                <el-radio-button label="2"
                                    >自定义</el-radio-button
                                >
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item
                            v-if="form.bannerType != 2"
                            label="链接地址"
                        >
                            <el-input
                                size="mini"
                                v-model="form.linkUrl"
                                class="formInput"
                                maxlength="25"
                            />
                        </el-form-item>
                        <el-form-item
                            label="内容"
                            :required="true"
                            class="formInput"
                            v-if="form.bannerType == 2"
                        >
                            <wang
                                :content-back="noticeContent"
                                @get-content="getContent"
                            />
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <el-button type="primary" @click="commit">提交</el-button>
                <el-button type="info" @click="close">取消</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import wang from "@/components/WangEditor";
import { upFile } from "@/api";
import { downFile } from "@/utils/file";
import { uploadAccept } from "@/utils/uploadAccept";
export default {
    name: "OperateCom",
    components: {
        wang,
    },
    props: {
        // 打开dialog的
        dialog: {
            type: Boolean, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: false, // 这样可以指定默认的值
        },
        info: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
        companyId: {
            type: String,
            default: "",
        },
        row: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
    },
    data() {
        return {
            upFile,
            show: this.dialog,
            form: {
                bannerTittle: "",
                // sort: "",
                linkUrl: "",
                bannerCoverUrl: "",
                content: "",
                companyId: this.companyId,
                bannerType: 1,
            },
            fileList: [],
            file: "",
            noticeContent: "",
        };
    },
    created() {},
    mounted() {
        this.upd();
    },
    methods: {
        upd() {
            if (this.info.addOr === "upd") {
                this.$axiosReq(
                    "/info/server/escort/web/company/banner/" + this.row.id,
                    null,
                    null,
                    "get"
                ).then((res) => {
                    this.form = res.data;
                    if (res.data.bannerCoverUrl) {
                        var url = this.$fileUrl + res.data.bannerCoverUrl;
                        this.fileList.push({ url: url, name: "封面图" });
                    }
                    this.noticeContent = res.data.content;
                });
            }
        },
        // 提交添加
        commit() {
            if (this.form.bannerType != 2) {
                this.form.content = "";
            } else {
                this.form.linkUrl = "";
                let src = this.form.content.substring(0, 5);
                if (src != "<meta") {
                    this.form.content =
                        '<html><meta charset="utf-8"><meta name="viewport" content="maximum-scale=1.0,minimum-scale=1.0,user-scalable=0,width=device-width,initial-scale=1.0" /><body style="font-size:14pt">' +
                        this.form.content +
                        "</body></html>";
                } else {
                    this.form.content =
                        "<html>" + this.form.content + "</html>";
                }
            }
            if (this.info.addOr === "add") {
                this.$axiosReq(
                    "/info/server/escort/web/company/banner",
                    this.form,
                    null,
                    "post"
                ).then((res) => {
                    this.close();
                    this.$emit("get-list");
                    this.$message.success(res.msg);
                });
            } else {
                this.$axiosReq(
                    "/info/server/escort/web/company/banner/" + this.row.id,
                    this.form,
                    null,
                    "put"
                ).then((res) => {
                    this.close();
                    this.$emit("get-list");
                    this.$message.success(res.msg);
                });
            }
        },
        // 关闭模态框
        close() {
            this.show = false;
            this.$emit("refresh");
        },
        success(response) {
            this.form.bannerCoverUrl = response.data.filePath;
        },
        change(file, fileList) {
            const fileSize = file.size / 1024 / 1024 < 500;
            if (!fileSize) {
                this.$message.error("文件大小不能超过500MB！！！");
                return false;
            }
            let flag = false;
            flag = uploadAccept(file, "图片");
            if (flag) {
                this.file = file;
            } else {
                fileList.splice(-1, 1);
            }
        },
        overFile() {
            this.$message.error("只支持传一张图片！");
        },
        onRemove() {
            this.form.bannerCoverUrl = "";
        },
        getContent(v) {
            this.form.content = v.content;
            this.noticeContent = v.content;
        },
    },
};
</script>

<style scoped>
.input-with-select {
    width: 280px;
}
.el-form {
    padding-right: 25px;
}

.pb-20 {
    padding-bottom: 20px;
}

.toolbar {
    border: 1px solid #ccc;
}

.text {
    border: 1px solid #ccc;
    min-height: 400px;
}
</style>

<template>
    <div>
        <OperateCom
            v-if="dialog.refresh"
            :dialog.sync="dialog.show"
            :row="dialog.row"
            :info="dialog.info"
            :companyId="comObj.id"
            @get-list="getList"
            @refresh="refreshDialog"
        />
        <div class="com-banner-manage">
            <div class="left-box">
                <CompanySelect @cellClick="onClick" />
            </div>
            <div class="right-box">
                <el-row type="flex" :gutter="20">
                    <el-col :span="24">
                        <div>企业名称 : {{ comObj.companyName }}</div>
                    </el-col>
                    <!--				<el-col>-->
                    <!--					<el-button type="primary" size="mini" style="margin-top: 5px;" @click="getList">搜 索</el-button>-->
                    <!--				</el-col>-->
                </el-row>

                <div style="margin-top: 15px">
                    <el-row>
                        <vxe-toolbar>
                            <template v-slot:buttons>
                                <el-row type="flex" justify="start">
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:banner:add'
                                            )
                                        "
                                        @click="
                                            openDialog({
                                                title: '添加Banner',
                                                addOr: 'add',
                                            })
                                        "
                                        >添加Banner</el-button
                                    >

                                    <el-button
                                        type="primary"
                                        plain
                                        size="mini"
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:banner:asyc'
                                            )
                                        "
                                        @click="getSysBanner"
                                        >同步Banner</el-button
                                    >

                                    <el-col :span="4" style="margin-left: 10px">
                                        <el-input
                                            size="mini"
                                            v-model="bannerTittle"
                                            type="text"
                                            :clearable="true"
                                            class="input searchInput"
                                            suffix-icon="el-icon-search"
                                            placeholder="请输入Banner名称"
                                            @input="getList"
                                        />
                                    </el-col>
                                    <!-- <el-col :span="1">
								<vxe-button @click="getList">刷新</vxe-button>
							</el-col> -->
                                </el-row>
                            </template>
                        </vxe-toolbar>
                        <vxe-table
                            ref="table"
                            border
                            resizable
                            :auto-resize="true"
                            :header-row-style="headerStyle"
                            :row-style="rowStyle"
                            align="center"
                            class="vxe-table-element"
                            height="590"
                            row-id="notice_id"
                            :sort-config="{ remote: true }"
                            :filter-config="{ remote: true }"
                            :checkbox-config="{ reserve: true }"
                            :row-config="{ isHover: true }"
                            :data="table.list"
                            :loading="loading"
                        >
                            <vxe-table-column type="seq" width="60" />
                            <vxe-table-column
                                field="bannerTittle"
                                title="标题"
                                show-overflow="title"
                            />
                            <!-- <vxe-table-column field="releaseTime" title="缩略图" show-overflow="title" /> -->
                            <vxe-table-column title="缩略图" width="130">
                                <template v-slot="{ row, rowIndex }">
                                    <el-popover
                                        v-if="row.bannerCoverUrl"
                                        placement="right"
                                        trigger="hover"
                                    >
                                        <img
                                            :src="$fileUrl + row.bannerCoverUrl"
                                            style="width: 500px; height: 500px"
                                        />
                                        <img
                                            slot="reference"
                                            :src="$fileUrl + row.bannerCoverUrl"
                                            style="width: 100px; height: 100px"
                                        />
                                    </el-popover>
                                    <img
                                        v-else
                                        :src="require('@/assets/user.png')"
                                        style="width: 100px; height: 100px"
                                    />
                                </template>
                            </vxe-table-column>
                            <vxe-table-column
                                field="createTime"
                                title="创建时间"
                                show-overflow="title"
                            />
                            <vxe-table-column
                                field="sort"
                                title="排序号"
                                show-overflow="title"
                            />
                            <vxe-table-column title="状态">
                                <template v-slot="{ row }">
                                    <el-switch
                                        ref="switch"
                                        v-model="row.status"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949"
                                        :active-value="0"
                                        :inactive-value="1"
                                        @change="
                                            (v) => {
                                                changeStatus(v, row);
                                            }
                                        "
                                    />
                                </template>
                            </vxe-table-column>
                            <vxe-table-column field="" title="操作">
                                <template v-slot="{ row, rowIndex }">
                                    <el-dropdown size="small" type="primary">
                                        <el-button size="small" type="primary">
                                            操作<i
                                                class="el-icon-arrow-down el-icon--right"
                                            />
                                        </el-button>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item
                                                v-if="row.status == 1"
                                                icon="el-icon-edit"
                                                @click.native="
                                                    openDialog(
                                                        {
                                                            title: '修改标签',
                                                            addOr: 'upd',
                                                        },
                                                        row
                                                    )
                                                "
                                                >修改</el-dropdown-item
                                            >
                                            <el-dropdown-item
                                                icon="el-icon-delete"
                                                @click.native="
                                                    remove(
                                                        { del: 'single' },
                                                        row
                                                    )
                                                "
                                                >删除</el-dropdown-item
                                            >
                                            <el-dropdown-item
                                                icon="el-icon-top"
                                                v-if="rowIndex != 0"
                                                @click.native="
                                                    changeSort(0, row.id)
                                                "
                                                >上一级</el-dropdown-item
                                            >
                                            <el-dropdown-item
                                                icon="el-icon-bottom"
                                                v-if="
                                                    rowIndex != table.total - 1
                                                "
                                                @click.native="
                                                    changeSort(1, row.id)
                                                "
                                                >下一级</el-dropdown-item
                                            >
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </vxe-table-column>
                        </vxe-table>
                        <div style="height: 20px" />
                        <el-pagination
                            background
                            :current-page="table.currentPage"
                            :page-sizes="[5, 10, 15, 20, 50, 100]"
                            :page-size="table.pageSize"
                            layout="total, ->, sizes, prev, pager, next, jumper"
                            :total="table.total"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                        />
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import OperateCom from "./OperateCom.vue";
import CompanySelect from "./CompanySelect.vue";
export default {
    name: "ComBannerManage",
    components: {
        OperateCom,
        CompanySelect,
    },
    data() {
        return {
            loading: false,
            startDate: "",
            endDate: "",
            date: "",
            releaseStatus: "",
            comObj: {},
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
            dialog: {
                row: Object,
                info: {},
                refresh: false, // 每次刷新DOM
                show: false,
            },
            bannerTittle: "",
        };
    },
    created() {
        // this.getList();
    },
    methods: {
        rowStyle,
        headerStyle,
        openDialog(info, row) {
            if (JSON.stringify(this.comObj) == "{}") {
                this.$message.warning("请先选择公司!!!");
                return;
            }
            this.dialog.refresh = true;
            this.dialog.show = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        refreshDialog() {
            this.dialog.refresh = false;
        },
        onClick(row) {
            this.comObj = row;
            this.getList();
        },
        changeSort(sort, id) {
            this.loading = true;
            this.$axiosReq(
                `/info/server/escort/web/company/banner/move/${id}/status/${sort}`,
                null,
                null,
                "put"
            )
                .then((res) => {
                    this.$message.success("修改成功!");
                    this.getList();
                })
                .finally((res) => {
                    this.loading = false;
                });
        },
        getList() {
            this.loading = true;
            this.$axiosReq(
                "/info/server/escort/web/company/banner",
                null,
                {
                    bannerTittle: this.bannerTittle,
                    companyId: this.comObj.id,
                    currentPage: this.table.currentPage,
                    pageSize: this.table.pageSize,
                    property: "sort",
                    direction: "ASC",
                },
                "get"
            )
                .then((res) => {
                    this.table.list = res.data.content;
                    this.table.total = Number(res.data.totalElements);
                })
                .finally((res) => {
                    this.loading = false;
                });
        },
        getSysBanner() {
            if (JSON.stringify(this.comObj) == "{}") {
                this.$message.warning("请先选择公司!!!");
                return;
            }
            this.$confirm("确认同步系统banner吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$axiosReq(
                        `/info/server/escort/web/company/banner/sync/companyId/${this.comObj.id}`,
                        null,
                        null,
                        "post"
                    ).then((res) => {
                        this.$message.success(res.msg);
                        this.getList();
                    });
                })
                .catch(() => {
                    this.$message.info("已取消同步！！！");
                });
        },
        remove(info, row) {
            this.$confirm("确认删除吗！！！", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$axiosReq(
                        "/info/server/escort/web/company/banner/" + row.id,
                        null,
                        null,
                        "delete"
                    ).then((res) => {
                        this.$message.success(res.msg);
                        this.getList();
                    });
                })
                .catch(() => {
                    this.$message.info("取消了删除！！！");
                });
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.table.list = [];
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.table.list = [];
            this.getList();
        },
        changeStatus(v, row) {
            this.$axiosReq(
                "/info/server/escort/web/company/banner/status//" + row.id,
                null,
                null,
                "put"
            ).then((res) => {
                this.getList();
                this.$message.success(res.msg);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.rowBox {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.com-banner-manage {
    width: 100%;
    display: flex;

    .left-box {
        width: 320px;
        border: 1px solid #e6ebf5;
        padding: 12px;
        margin-right: 15px;
    }
    .right-box {
        flex: 1;
        border: 1px solid #e6ebf5;
        padding: 12px;
    }
}
</style>

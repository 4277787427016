var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dialog.refresh)?_c('Operate',{attrs:{"dialog":_vm.dialog.show,"row":_vm.dialog.row,"info":_vm.dialog.info},on:{"update:dialog":function($event){return _vm.$set(_vm.dialog, "show", $event)},"get-list":_vm.getList,"refresh":_vm.refreshDialog}}):_vm._e(),_c('el-card',[_c('el-row',{attrs:{"type":"flex","gutter":20}},[_c('el-col',{attrs:{"span":5}},[_c('el-input',{staticClass:"input searchInput",attrs:{"size":"mini","type":"text","suffix-icon":"el-icon-search","placeholder":"请输入Banner名称"},on:{"input":_vm.getList},model:{value:(_vm.bannerTittle),callback:function ($$v) {_vm.bannerTittle=$$v},expression:"bannerTittle"}})],1)],1)],1),_c('el-row',{staticStyle:{"margin-top":"15px"}},[_c('vxe-toolbar',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('el-row',{attrs:{"type":"flex","justify":"start"}},[(_vm.$hasBtn('sys:permissions:org:banner:add'))?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.openDialog({
                                    title: '添加Banner',
                                    addOr: 'add',
                                })}}},[_vm._v("添加Banner")]):_vm._e(),(
                                !_vm.isSuperAdmin &&
                                _vm.$hasBtn('sys:permissions:org:banner:asyc')
                            )?_c('el-button',{attrs:{"type":"primary","plain":"","size":"mini"},on:{"click":_vm.getSysBanner}},[_vm._v("同步Banner")]):_vm._e()],1)]},proxy:true}])}),_c('vxe-table',{ref:"table",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyle,"align":"center","height":_vm.tableHeight,"row-id":"notice_id","sort-config":{ remote: true },"filter-config":{ remote: true },"checkbox-config":{ reserve: true },"data":_vm.table.list,"loading":_vm.loading}},[_c('vxe-table-column',{attrs:{"type":"seq","width":"60"}}),_c('vxe-table-column',{attrs:{"field":"bannerTittle","title":"标题","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"title":"缩略图","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var row = ref.row;
                            var rowIndex = ref.rowIndex;
return [(row.bannerCoverUrl)?_c('el-popover',{attrs:{"placement":"right","trigger":"hover"}},[_c('img',{staticStyle:{"width":"500px","height":"500px"},attrs:{"src":_vm.$fileUrl + row.bannerCoverUrl}}),_c('img',{staticStyle:{"width":"100px","height":"100px"},attrs:{"slot":"reference","src":_vm.$fileUrl + row.bannerCoverUrl},slot:"reference"})]):_c('img',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":require('@/assets/user.png')}})]}}])}),_c('vxe-table-column',{attrs:{"field":"createTime","title":"创建时间","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"sort","title":"排序号","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"title":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var row = ref.row;
return [_c('el-switch',{ref:"switch",attrs:{"active-color":"#13ce66","inactive-color":"#ff4949","active-value":0,"inactive-value":1},on:{"change":function (v) {
                                    _vm.changeStatus(v, row);
                                }},model:{value:(row.status),callback:function ($$v) {_vm.$set(row, "status", $$v)},expression:"row.status"}})]}}])}),_c('vxe-table-column',{attrs:{"field":"","title":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
                                var rowIndex = ref.rowIndex;
return [_c('el-dropdown',{attrs:{"size":"small","type":"primary"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v(" 操作"),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(row.status == 1)?_c('el-dropdown-item',{attrs:{"icon":"el-icon-edit"},nativeOn:{"click":function($event){return _vm.openDialog(
                                            {
                                                title: '修改标签',
                                                addOr: 'upd',
                                            },
                                            row
                                        )}}},[_vm._v("修改")]):_vm._e(),_c('el-dropdown-item',{attrs:{"icon":"el-icon-delete"},nativeOn:{"click":function($event){return _vm.remove({ del: 'single' }, row)}}},[_vm._v("删除")]),(rowIndex != 0)?_c('el-dropdown-item',{attrs:{"icon":"el-icon-top"},nativeOn:{"click":function($event){return _vm.changeSort(0, row.id)}}},[_vm._v("上一级")]):_vm._e(),(rowIndex != _vm.table.total - 1)?_c('el-dropdown-item',{attrs:{"icon":"el-icon-bottom"},nativeOn:{"click":function($event){return _vm.changeSort(1, row.id)}}},[_vm._v("下一级")]):_vm._e()],1)],1)]}}])})],1),_c('div',{staticStyle:{"height":"20px"}}),_c('el-pagination',{attrs:{"background":"","current-page":_vm.table.currentPage,"page-sizes":[5, 10, 15, 20, 50, 100],"page-size":_vm.table.pageSize,"layout":"total, ->, sizes, prev, pager, next, jumper","total":_vm.table.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
        <Operate
            v-if="dialog.refresh"
            :dialog.sync="dialog.show"
            :row="dialog.row"
            :info="dialog.info"
            @get-list="getList"
            @refresh="refreshDialog"
        />
        <el-card>
            <el-row type="flex" :gutter="20">
                <el-col :span="5">
                    <el-input
                        size="mini"
                        v-model="bannerTittle"
                        type="text"
                        class="input searchInput"
                        suffix-icon="el-icon-search"
                        placeholder="请输入Banner名称"
                        @input="getList"
                    />
                </el-col>
                <!--				<el-col>-->
                <!--					<el-button type="primary" size="mini" style="margin-top: 5px;" @click="getList">搜 索</el-button>-->
                <!--				</el-col>-->
            </el-row>
        </el-card>

        <el-row style="margin-top: 15px">
            <vxe-toolbar>
                <template v-slot:buttons>
                    <el-row type="flex" justify="start">
                        <el-button
                            type="primary"
                            v-if="$hasBtn('sys:permissions:org:banner:add')"
                            size="mini"
                            @click="
                                openDialog({
                                    title: '添加Banner',
                                    addOr: 'add',
                                })
                            "
                            >添加Banner</el-button
                        >

                        <el-button
                            type="primary"
                            plain
                            size="mini"
                            @click="getSysBanner"
                            v-if="
                                !isSuperAdmin &&
                                $hasBtn('sys:permissions:org:banner:asyc')
                            "
                            >同步Banner</el-button
                        >

                        <!-- <el-col :span="1">
								<vxe-button @click="getList">刷新</vxe-button>
							</el-col> -->
                    </el-row>
                </template>
            </vxe-toolbar>
            <vxe-table
                ref="table"
                border
                resizable
                :auto-resize="true"
                :header-row-style="headerStyle"
                :row-style="rowStyle"
                align="center"
                class="vxe-table-element"
                :height="tableHeight"
                row-id="notice_id"
                :sort-config="{ remote: true }"
                :filter-config="{ remote: true }"
                :checkbox-config="{ reserve: true }"
                :data="table.list"
                :loading="loading"
            >
                <vxe-table-column type="seq" width="60" />
                <vxe-table-column
                    field="bannerTittle"
                    title="标题"
                    show-overflow="title"
                />
                <!-- <vxe-table-column field="releaseTime" title="缩略图" show-overflow="title" /> -->
                <vxe-table-column title="缩略图" width="130">
                    <template v-slot="{ row, rowIndex }">
                        <el-popover
                            v-if="row.bannerCoverUrl"
                            placement="right"
                            trigger="hover"
                        >
                            <img
                                :src="$fileUrl + row.bannerCoverUrl"
                                style="width: 500px; height: 500px"
                            />
                            <img
                                slot="reference"
                                :src="$fileUrl + row.bannerCoverUrl"
                                style="width: 100px; height: 100px"
                            />
                        </el-popover>
                        <img
                            v-else
                            :src="require('@/assets/user.png')"
                            style="width: 100px; height: 100px"
                        />
                    </template>
                </vxe-table-column>
                <vxe-table-column
                    field="createTime"
                    title="创建时间"
                    show-overflow="title"
                />
                <vxe-table-column
                    field="sort"
                    title="排序号"
                    show-overflow="title"
                />
                <vxe-table-column title="状态">
                    <template v-slot="{ row }">
                        <el-switch
                            ref="switch"
                            v-model="row.status"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            :active-value="0"
                            :inactive-value="1"
                            @change="
                                (v) => {
                                    changeStatus(v, row);
                                }
                            "
                        />
                    </template>
                </vxe-table-column>
                <vxe-table-column field="" title="操作">
                    <template v-slot="{ row, rowIndex }">
                        <el-dropdown size="small" type="primary">
                            <el-button size="small" type="primary">
                                操作<i
                                    class="el-icon-arrow-down el-icon--right"
                                />
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    v-if="row.status == 1"
                                    icon="el-icon-edit"
                                    @click.native="
                                        openDialog(
                                            {
                                                title: '修改标签',
                                                addOr: 'upd',
                                            },
                                            row
                                        )
                                    "
                                    >修改</el-dropdown-item
                                >
                                <el-dropdown-item
                                    icon="el-icon-delete"
                                    @click.native="
                                        remove({ del: 'single' }, row)
                                    "
                                    >删除</el-dropdown-item
                                >
                                <el-dropdown-item
                                    icon="el-icon-top"
                                    v-if="rowIndex != 0"
                                    @click.native="changeSort(0, row.id)"
                                    >上一级</el-dropdown-item
                                >
                                <el-dropdown-item
                                    icon="el-icon-bottom"
                                    v-if="rowIndex != table.total - 1"
                                    @click.native="changeSort(1, row.id)"
                                    >下一级</el-dropdown-item
                                >
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </vxe-table-column>
            </vxe-table>
            <div style="height: 20px" />
            <el-pagination
                background
                :current-page="table.currentPage"
                :page-sizes="[5, 10, 15, 20, 50, 100]"
                :page-size="table.pageSize"
                layout="total, ->, sizes, prev, pager, next, jumper"
                :total="table.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </el-row>
    </div>
</template>

<script>
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import Operate from "./Operate";
export default {
    name: "BannerManage",
    components: {
        Operate,
    },
    data() {
        return {
            loading: false,
            startDate: "",
            endDate: "",
            date: "",
            releaseStatus: "",
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
            dialog: {
                row: Object,
                info: {},
                refresh: false, // 每次刷新DOM
                show: false,
            },
            bannerTittle: "",
        };
    },
    created() {
        this.getList();
    },
    computed: {
        tableHeight() {
            const userInfo = JSON.parse(sessionStorage.getItem("UserDetail"));

            if (
                Array.isArray(userInfo.roles) &&
                userInfo.roles.includes("superAdmin")
            ) {
                return "560";
            }

            return "620";
        },
        isSuperAdmin() {
            const userInfo = JSON.parse(sessionStorage.getItem("UserDetail"));

            if (
                Array.isArray(userInfo.roles) &&
                userInfo.roles.includes("superAdmin")
            ) {
                return true;
            }

            return false;
        },
        httpUrl() {
            const userInfo = JSON.parse(sessionStorage.getItem("UserDetail"));

            if (
                Array.isArray(userInfo.roles) &&
                userInfo.roles.includes("superAdmin")
            ) {
                return "/info/server/escort/web/banner/";
            }

            return "/info/server/escort/web/company/banner/";
        },
    },
    methods: {
        rowStyle,
        headerStyle,
        openDialog(info, row) {
            this.dialog.refresh = true;
            this.dialog.show = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        refreshDialog() {
            this.dialog.refresh = false;
        },
        getSysBanner() {
            this.$confirm("确认同步系统banner吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$axiosReq(
                        `${this.httpUrl}sync/companyId/${sessionStorage.getItem(
                            "CompanyId"
                        )}`,
                        null,
                        null,
                        "post"
                    ).then((res) => {
                        this.$message.success(res.msg);
                        this.getList();
                    });
                })
                .catch(() => {
                    this.$message.info("已取消同步！！！");
                });
        },
        changeSort(sort, id) {
            this.loading = true;
            this.$axiosReq(
                `${this.httpUrl}move/${id}/status/${sort}`,
                null,
                null,
                "put"
            )
                .then((res) => {
                    this.$message.success("修改成功!");
                    this.getList();
                })
                .finally((res) => {
                    this.loading = false;
                });
        },
        getList() {
            this.loading = true;
            this.$axiosReq(
                this.httpUrl,
                null,
                {
                    bannerTittle: this.bannerTittle,
                    currentPage: this.table.currentPage,
                    pageSize: this.table.pageSize,
                    companyId: sessionStorage.getItem("CompanyId"),
                    property: "sort",
                    direction: "ASC",
                },
                "get"
            )
                .then((res) => {
                    this.table.list = res.data.content;
                    this.table.total = Number(res.data.totalElements);
                })
                .finally((res) => {
                    this.loading = false;
                });
        },
        remove(info, row) {
            this.$confirm("确认删除吗！！！", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$axiosReq(
                        this.httpUrl + row.id,
                        null,
                        null,
                        "delete"
                    ).then((res) => {
                        this.$message.success(res.msg);
                        this.getList();
                    });
                })
                .catch(() => {
                    this.$message.info("取消了删除！！！");
                });
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.table.list = [];
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.table.list = [];
            this.getList();
        },
        changeStatus(v, row) {
            this.$axiosReq(this.httpUrl + "status/" + row.id, null, null, "put")
                .then((res) => {
                    this.getList();
                    this.$message.success(res.msg);
                })
                .catch((err) => {
                    this.getList();
                });
        },
    },
};
</script>

<style scoped>
.rowBox {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
</style>

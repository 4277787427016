<template>
    <div class="banner-box">
        <el-tabs
            v-model="activeName"
            type="card"
            @tab-click="handleClick"
            v-if="isSuperAdmin"
        >
            <el-tab-pane label="banner管理" name="first"> </el-tab-pane>
            <el-tab-pane label="企业banner管理" name="second"> </el-tab-pane>
        </el-tabs>
        <BannerManage v-if="activeName == 'first'" />
        <ComBannerManage v-else />
    </div>
</template>

<script>
import BannerManage from "./BannerManage.vue";
import ComBannerManage from "./ComBannerManage.vue";

export default {
    name: "Index",
    components: { BannerManage, ComBannerManage },
    data() {
        return {
            activeName: "first",
        };
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
    },
    computed: {
        isSuperAdmin() {
            const userInfo = JSON.parse(sessionStorage.getItem("UserDetail"));

            if (
                Array.isArray(userInfo.roles) &&
                userInfo.roles.includes("superAdmin")
            ) {
                return true;
            }

            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
.rowBox {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.banner-box {
    width: calc(100% - 62px);
    height: calc(100vh - 100px);
    margin: 30px 16px;
    border: 1px solid #e6ebf5;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border-radius: 6px;
    padding: 15px;
}
</style>

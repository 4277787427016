<template>
    <div class="com-select-box">
        <div class="com-title">企业列表</div>
        <el-row type="flex" justify="space-between" :gutter="50">
            <el-col :span="16">
                <el-input
                    v-model="condition.search"
                    class="input searchInput"
                    size="mini"
                    suffix-icon="el-icon-search"
                    placeholder="搜索"
                    :clearable="true"
                    @input="changeCondition"
                />
            </el-col>
        </el-row>

        <el-row style="margin-top: 15px">
            <el-empty
                description="暂无数据"
                v-if="table.list.length == 0"
            ></el-empty>
            <div class="list-box" v-infinite-scroll="load" v-else>
                <div v-for="(item, index) in table.list" :key="index">
                    <div
                        class="list-item"
                        :class="{
                            'is-active': item.id == activeId ? true : false,
                        }"
                        @click="onCellClick(item)"
                    >
                        {{ item.companyName }}
                    </div>
                </div>
                <p v-if="loading" class="list-tips">加载中...</p>
                <p v-if="table.currentPage == table.total" class="list-tips">
                    没有更多了
                </p>
            </div>
        </el-row>
        <div class="page-box" style="height: 20px">
            <!-- <el-pagination
                background
                small=""
                layout="prev, pager, next"
                :current-page="table.currentPage"
                :page-size="table.pageSize"
                :total="table.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            /> -->
        </div>
    </div>
</template>

<script>
import {
    company,
    companyStatus,
    getAreaChildren,
    getDictParentCode,
    getAreaTreeByCode,
    areaParentCode,
} from "@/api";

export default {
    name: "CompanySelect",
    components: {},
    emits: ["cellClick"],
    data() {
        return {
            noMore: false,
            loading: false,
            activeItem: {},
            activeId: "",
            condition: {
                areaCode: {},
                // cityCode: null,
                // provinceCode: null,
                searchName: null,
                companyType: "",
                industry: "",
                currentPage: 1,
                pageSize: 10,
                direction: "",
                provinceCode: "",
                cityCode: "",
                areaCode: "",
            },
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 25,
                sortName: "",
                sortBy: "",
            },
            dialog: {
                row: Object,
                info: {},
                refresh: false, // 每次刷新DOM
                refreshP: false, // 每次刷新DOM
                show: false,
                showP: false,
            },
            region: [],
            industry: [],
            companyType: [],
            loginInfo: {},
        };
    },
    created() {
        this.getList();
    },
    methods: {
        // 表格点击事件
        onCellClick(row) {
            console.log(row);
            this.activeItem = row;
            this.activeId = row.id;
            this.$emit("cellClick", row);
        },
        changeCondition(v) {
            this.table.currentPage = 1;
            this.getList();
        },

        getList() {
            this.loading = true;
            this.$axiosReq(
                company,
                null,
                {
                    provinceCode: this.condition.provinceCode,
                    cityCode: this.condition.cityCode,
                    areaCode: this.condition.areaCode,
                    industry: this.condition.industry,
                    searchName: this.condition.search,
                    companyType: this.condition.companyType,
                    currentPage: this.table.currentPage,
                    pageSize: this.table.pageSize,
                },
                "get"
            )
                .then((res) => {
                    this.table.list =
                        res.data.currentPage == "1"
                            ? res.data.content
                            : this.table.list.concat(res.data.content);
                    this.table.total = Number(Number(res.data.totalPage));
                    this.table.currentPage = Number(res.data.currentPage);
                })
                .finally((e) => {
                    this.loading = false;
                });
        },

        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.getList();
        },
        load() {
            if (this.table.currentPage < this.table.total) {
                this.table.currentPage = this.table.currentPage + 1;
                this.getList();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.com-select-box {
    width: 100%;
    height: calc(100vh - 220px);
    position: relative;
}
.com-title {
    margin-bottom: 10px;
}

.list-box {
    height: 650px;
    border: 1px solid #e6ebf5;
    // border-top: none;
    overflow: auto;
}
.list-item {
    height: 30px;
    border-top: 1px solid #e6ebf5;
    display: flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    color: rgb(96, 98, 102);

    font-size: 14px;
}

.list-item:hover {
    background: #e6ebf5;
}

.is-active {
    background: #e6ebf5;
}

.page-box {
    position: absolute;
    bottom: 0px;
    width: 300px;
    display: flex;
    justify-content: center;
}

.list-tips {
    width: 100%;
    text-align: center;
    color: rgb(96, 98, 102);

    font-size: 14px;
    border-top: 1px solid #e6ebf5;
    padding: 8px;
}
</style>
